/** @jsxImportSource @emotion/react */
import React from "react";
import ReactTooltip from "react-tooltip";
import Immutable from "immutable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBringForward, faLock} from "@fortawesome/pro-regular-svg-icons";

import {betterMemo} from "js/common/utils/more-memo";
import Tabs from "js/common/views/tabs";
import EditSimpleSumColumns from "js/admin/kpis/edit-kpis/tabs/edit-simple-sum-columns";
import EditColumns from "js/admin/kpis/edit-kpis/tabs/edit-columns";
import EditConfig from "js/admin/kpis/edit-kpis/tabs/edit-config";
import DateSettingsSection from "js/admin/kpis/edit-kpis/tabs/date-settings-section";
import OwnershipSection from "js/admin/kpis/edit-kpis/tabs/ownership";
import JsonField from "js/common/views/inputs/formatted-json-field";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import CoreTypeFiltersEditor from "js/admin/kpis/edit-kpis/tabs/core-type-filters-editor";
import OtherConfigEditor from "js/admin/kpis/edit-kpis/tabs/other-config-editor";
import {
  getQueryParam,
  getQueryType,
  isKpiEditable,
  isRestrictedQueryType,
  useQueryParamChange,
  setQueryParam,
  useCalculatedImmutableState
} from "js/admin/kpis/edit-kpis/utils";
import {ConfigurableTest, SimpleTest} from "js/admin/kpis/edit-kpis/tabs/test";
import {SingleMetricImportExport} from "js/admin/kpis/edit-kpis/tabs/import-export";
import MetricRelationships from "js/admin/kpis/edit-kpis/tabs/relationships/relationships";
import {ForwardReportInheritedConfig, ParentWarning} from "js/admin/kpis/edit-kpis/warnings";
import {getIssuesWithWrappedKpi, getNameForValidation, hasTestError} from "js/admin/kpis/edit-kpis/validation";
import Explanation from "js/admin/kpis/edit-kpis/tabs/explanation";
import Error from "js/common/views/error";
import currentClient from "js/common/repo/backbone/current-client";
import * as Users from "js/common/users";
import {Button, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Config from "js/admin/kpis/edit-kpis/tabs/config";
import * as Auditor from "js/common/auditer";

const rootChangeWarning = "The root entity on this Metric has been changed. Columns and filters have been reset to default.";

const TabTitle = ({theme, icon, text, hasError, hasWarning}) => <span data-test-id={`kpi-tab-${text}`}>
  <i className={`fa fa-${icon}`} style={{marginRight: "0.3rem"}} />
  {text}
  {(hasError || hasWarning) && <i
      className={`fa fa-exclamation`}
      style={{
        color: hasError ? "red" : theme.palette.primary.main,
        marginLeft: "0.5rem"
      }} />}
</span>;

const TabbedKpiEditor = betterMemo(
    {debug: false, displayName: "TabbedKpiEditor"},
    ({
      theme,
      isCube19User,
      clientHasEdit,
      isLockedByOnboarding,
      columns,
      submitError,
      onColumnsChange,
      onUnmirror,
      kpisWithMatchingRootEntity,
      masterMetricsAvailableToCombineWith,
      kpisThatShareColumnsWithThisKpi,
      kpi,
      entityNames,
      parentCombinedKpi,
      combinedKpi,
      combineError,
      onKpiChange,
      kpiIsParent,
      testResult,
      onTest,
      testConfig,
      onTestConfigChange,
      idToEntityColumn,
      actionTypes,
      idToTemplate,
      availableOwners,
      typeToGroupingEntity,
      rootChanged,
      kpiIdToMasterKpis,
      onDependencyClick,
      queryType,
      hasNewConfig,
      isEditor,
      hasRelationships,
      kpiIdToDependentKpis,
      kpiIdToContributingKpis,
      requiresExplanationUpdate,
      tabId,
      searchFocused,
      filterText,
      onChangeTab,
      onClearRequiresExplanationUpdate
    }) => {

      const kpiIsChild = !!(kpi.get("combineWithKpiId") ?? kpi.get("combineWithMasterMetricType"));
      const parentHasForwardReportConfig = parentCombinedKpi && getQueryParam(parentCombinedKpi, "forwardReport");
      const kpiHasForwardReportConfig = getQueryParam(kpi, "forwardReport");
      const template = idToTemplate.get(kpi.get("templateId")) || Immutable.Map();

      const handleCoreTypeFiltersChange = useQueryParamChange(kpi, onKpiChange, "coreTypeFilters");
      const kpisAvailableToCombineWith = kpisWithMatchingRootEntity
          .filter(k => !isNaN(k.get("id")) && k.get("id") !== kpi.get("id"));
      const currentUser = Users.getCurrentUser();

      const tabIds = getTabIds(
          isEditor,
          isCube19User,
          clientHasEdit,
          isLockedByOnboarding,
          queryType,
          hasNewConfig,
          hasRelationships,
          currentClient.areExplanationsEnabled(),
          Users.canAccessApp(currentUser, "kpi_admin")
      );
      const tabIndex = tabId ? tabIds.indexOf(tabId) : 0;

      const [rootNodes, cyclingKpis] = React.useMemo(
          () =>
              getAllRootNodes(kpi, kpiIdToContributingKpis, kpiIdToDependentKpis),
          [kpi, kpiIdToContributingKpis, kpiIdToDependentKpis]);

      const getExplanationWarning = React.useCallback(() => <Error
          text={<div>You have changed this metric's config. Please check the <span
              style={{
                cursor: "pointer",
                textDecoration: "underline"
              }} onClick={() => onChangeTab(kpi.get("id"), "EXPLANATION")}>Metric Explanation</span> to ensure it is up
            to
            date.</div>}
          type="warn"
          style={{marginTop: 0}} />, [kpi, onChangeTab]);


      const tabsToGetTab = Immutable.Map({
        "CONFIG": () => {
          return {
            title: <TabTitle theme={theme} icon="info" text="Config" hasError={!!combineError || !!submitError} />,
            content: <Config
                queryType={queryType}
                kpi={kpi}
                entityNames={entityNames}
                availableOwners={availableOwners}
                columns={(columns || Immutable.List()).filter(c => c.get("isFilterable"))}
                onChange={onKpiChange}
                kpisAvailableToCombineWith={kpisAvailableToCombineWith}
                kpiIdToDependentKpis={kpiIdToDependentKpis}
                rootNodes={rootNodes}
                cyclingKpis={cyclingKpis}
                onKpiChange={onKpiChange}
                searchFocused={searchFocused}
                filterText={filterText}
                parentKpi={parentCombinedKpi}
                idToTemplate={idToTemplate}
                combinedKpi={combinedKpi}
                combineError={combineError}
            />
          };
        },
        "COLUMNS": () => queryType === "SIMPLE_SUM" ? {
          title: <TabTitle theme={theme} icon="list" text="Columns" hasError={!!submitError} />,
          content: <EditSimpleSumColumns
              columns={columns}
              submitError={submitError}
              columnsEditable={template.get("columnsEditable")}
              onChange={onColumnsChange}
              rootChangedWarning={rootChanged && rootChangeWarning} />
        } : {
          title: <TabTitle theme={theme} icon="list" text="Columns" hasError={!!submitError} />,
          content: <EditColumns
              kpi={kpi}
              columns={columns}
              submitError={submitError}
              columnsEditable={template.get("columnsEditable")}
              onChange={onColumnsChange}
              onMirror={kpiId => onKpiChange(kpi.set("columnsKpiId", kpiId))}
              onUnmirror={() => onUnmirror(kpi)}
              kpisAvailableToMirror={kpisAvailableToCombineWith}
              kpisThatShareColumnsWithThisKpi={kpisThatShareColumnsWithThisKpi}
              rootChangedWarning={rootChanged && rootChangeWarning} />
        },
        "GENERAL": () => {
          return {
            title: <TabTitle theme={theme} icon="info" text="General" hasError={!!combineError || !!submitError} />,
            content: <EditConfig
                kpi={kpi}
                combinedKpi={combinedKpi}
                parentCombinedKpi={parentCombinedKpi}
                combineError={combineError}
                submitError={submitError}
                onChange={onKpiChange}
                kpiIsParent={kpiIsParent}
                kpiIsChild={kpiIsChild}
                testResult={testResult}
                onTest={onTest}
                actionTypes={actionTypes}
                idToTemplate={idToTemplate}
                idToEntityColumn={idToEntityColumn}
                typeToGroupingEntity={typeToGroupingEntity}
                queryType={queryType}
                rootChangedWarning={rootChanged && rootChangeWarning}
                isCube19User={isCube19User}
                kpisAvailableToCombineWith={kpisAvailableToCombineWith}
                masterMetricsAvailableToCombineWith={masterMetricsAvailableToCombineWith}
                kpiIdToMasterKpis={kpiIdToMasterKpis}
                requiresExplanationUpdate={requiresExplanationUpdate}
                getExplanationWarning={getExplanationWarning}
            />
          };
        },
        "DATE": () => {
          return {
            title: <TabTitle theme={theme} icon="clock-o" text="Date" />,
            content: <DateSettingsSection
                idToEntityColumn={idToEntityColumn}
                idToTemplate={idToTemplate}
                typeToGroupingEntity={typeToGroupingEntity}
                kpi={kpi}
                combinedKpi={combinedKpi}
                onKpiChange={onKpiChange}
                kpiIsParent={kpiIsParent}
                kpiIsChild={kpiIsChild}
                testResult={testResult}
                onTest={onTest}
                queryType={queryType}
                rootChangedWarning={rootChanged && rootChangeWarning}
                requiresExplanationUpdate={requiresExplanationUpdate}
                getExplanationWarning={getExplanationWarning} />
          };
        },
        "FAST_FILTER": () => {
          return {
            title: <TabTitle theme={theme} icon="filter" text="Fast Filter" />,
            content: <CoreTypeFiltersEditor
                combinedKpi={combinedKpi}
                idToEntityColumn={idToEntityColumn}
                typeToGroupingEntity={typeToGroupingEntity}
                rootGroupingEntity={kpi.get("readOnlyRootGroupingEntity")}
                filters={getQueryParam(kpi, "coreTypeFilters", new Immutable.List())}
                onFiltersChange={handleCoreTypeFiltersChange}
                kpiIsParent={kpiIsParent}
                kpiIsChild={kpiIsChild}
                testResult={testResult}
                onTest={onTest}
                queryType={queryType}
                rootChangedWarning={rootChanged && rootChangeWarning}
                requiresExplanationUpdate={requiresExplanationUpdate}
                getExplanationWarning={getExplanationWarning} />
          };
        },
        "ACTIONABLE_INSIGHT": () => {
          const forwardReport = hasNewConfig ?
              kpi.get("config").get("forwardReport") :
              getQueryParam(kpi, "forwardReport");
          const handleChange = hasNewConfig ?
              forwardReport => onKpiChange(kpi.setIn(["config", "forwardReport"], forwardReport)) :
              forwardReport => onKpiChange(setQueryParam(kpi, "forwardReport", forwardReport));
          return {
            title: <TabTitle theme={theme} icon="forward" text="Actionable Insight" />,
            content: <div style={{padding: "1rem"}}>
              {kpiIsParent && <ParentWarning />}
              {requiresExplanationUpdate && getExplanationWarning()}
              {parentHasForwardReportConfig && !kpiHasForwardReportConfig && <ForwardReportInheritedConfig />}
              <JsonField
                  label="Forward Report (JSON)"
                  value={forwardReport || Immutable.Map()}
                  onChange={handleChange}
                  style={{flex: 1, marginBottom: "1rem", marginRight: "1rem"}} />
              <SimpleTest
                  testResult={testResult}
                  onTest={onTest} />
            </div>
          };
        },
        "OTHER": () => {
          return {
            title: <TabTitle theme={theme} icon="globe" text="Other" />,
            content: <OtherConfigEditor
                theme={theme}
                kpi={kpi}
                combinedKpi={combinedKpi}
                onKpiChange={onKpiChange}
                kpiIsParent={kpiIsParent}
                kpiIsChild={kpiIsChild}
                testResult={testResult}
                onTest={onTest}
                requiresExplanationUpdate={requiresExplanationUpdate}
                getExplanationWarning={getExplanationWarning} />
          };
        },
        "TEST": () => {
          return {
            title: <TabTitle
                theme={theme}
                icon="tachometer"
                text="Test"
                hasError={!!testResult && hasTestError(testResult)} />,
            content: <ConfigurableTest
                testConfig={testConfig}
                onConfigChange={onTestConfigChange}
                template={template}
                testResult={testResult}
                onTest={onTest} />
          };
        },
        "IMPORT_EXPORT": () => {
          return {
            title: <TabTitle theme={theme} icon="file" text="Import / Export" />,
            content: <SingleMetricImportExport
                kpi={kpi}
                onKpiChange={onKpiChange}
                kpiIsParent={kpiIsParent}
                columns={columns}
                onColumnsChange={onColumnsChange}
                requiresExplanationUpdate={requiresExplanationUpdate}
                getExplanationWarning={getExplanationWarning} />
          };
        },
        "OWNERSHIP": () => {
          return {
            title: <TabTitle theme={theme} icon="link" text="Ownership" />,
            content: <OwnershipSection
                theme={theme}
                kpi={kpi}
                onKpiChange={onKpiChange}
                idToEntityColumn={idToEntityColumn}
                availableOwners={availableOwners}
                requiresExplanationUpdate={requiresExplanationUpdate}
                getExplanationWarning={getExplanationWarning}
            />
          };
        },
        "RELATIONSHIPS": () => {
          return {
            title: <TabTitle theme={theme} icon="project-diagram" text="Relationships" hasError={!!cyclingKpis} />,
            content: <MetricRelationships
                kpiIdToDependentKpis={kpiIdToDependentKpis}
                kpiIdToContributingKpis={kpiIdToContributingKpis}
                onDependencyClick={onDependencyClick}
                kpi={kpi}
                cyclingKpis={cyclingKpis}
                rootNodes={rootNodes}
            />
          };
        },
        "EXPLANATION": () => {
          let columnNameToKpi = Immutable.fromJS({
            "Parent KPI Value": parentCombinedKpi,
            "This KPI Value": kpi,
            "Final KPI Value": combinedKpi
          });
          if (!parentCombinedKpi) {
            columnNameToKpi = columnNameToKpi.deleteAll(["Parent KPI Value", "This KPI Value"]);
          }
          return {
            title: <TabTitle
                theme={theme}
                icon={"question"}
                text={"Explanation"}
                hasWarning={requiresExplanationUpdate} />,
            content: <Explanation
                kpi={kpi}
                onKpiChange={onKpiChange}
                showConfigOverview={isEditor && isCube19User}
                columnNameToMap={columnNameToKpi.map(k => k.deleteAll(["readOnlyCombined", "order"]))}
                idToTemplate={idToTemplate}
                requiresExplanationUpdate={requiresExplanationUpdate}
                onClearRequiresExplanationUpdate={onClearRequiresExplanationUpdate} />
          };
        }
      });

      const generatedTabs = tabIds.map(tab => tabsToGetTab.get(tab)()).toArray();

      return <Tabs
          selectedIndex={tabIndex}
          onChangeTab={tabIndex => onChangeTab(kpi.get("id"), tabIds.get(tabIndex))}
          tabs={generatedTabs} />;
    });

const getMirrorTooltipContent = (kpi, kpisThatShareColumnsWithThisKpi) => <div style={{padding: "0 0.5rem"}}>
  <p>Columns on this metric are mirrored to:</p>
  <ul>
    {kpisThatShareColumnsWithThisKpi.map(kpi => {
      return <li key={kpi.get("id")}>
        {kpi.get("name")}
      </li>;
    })}
  </ul>
</div>;

const EditKpiRow = betterMemo({debug: false, displayName: "Row"}, (
    {
      theme,
      isEditor,
      isCube19User,
      clientHasEdit,
      idToEntityColumn,
      actionTypes,
      typeToGroupingEntity,
      availableOwners,
      idToTemplate,
      hasChanged,
      isNameDupe,
      kpi,
      parentCombinedKpi,
      combinedKpi,
      entityNames,
      combineError,
      onKpiChange,
      columns,
      submitError,
      onColumnsChange,
      onUnmirror,
      kpisWithMatchingRootEntity,
      kpisWithMatchingColumns,
      mirrorColor,
      testResult,
      testConfig,
      onTestConfigChange,
      onTest,
      expanded,
      onExpandClick,
      rootChanged,
      kpiIdToMasterKpis,
      onNavigationClick,
      handleDuplicateClick,
      handleInheritClick,
      onRevertToLegacyFormat,
      kpiIdToDependentKpis,
      kpiIdToContributingKpis,
      tabId,
      onChangeTab,
      filterText,
      searchFocused,
      requiresExplanationUpdate,
      onClearRequiresExplanationUpdate,
      onDisableMetricToggle,
      onDeleteMetric
    }) => {
  const handleColumnsChange = React.useCallback(
      newColumns => onColumnsChange(
          newColumns,
          kpi.get("columnsKpiId") || kpi.get("id"),
          kpisWithMatchingColumns.map(kpi => kpi.get("id"))),
      [onColumnsChange, kpi, kpisWithMatchingColumns]);

  const handleTestConfigChange = React.useCallback(
      (testConfig) => onTestConfigChange(kpi.get("id"), testConfig),
      [onTestConfigChange, kpi]);

  const handleTest = React.useCallback(
      () => onTest(kpi.get("id")),
      [onTest, kpi]);

  const expandIcon = expanded
      ? <i style={{fontSize: 10}} className="bhi-sort-asc" />
      : <i style={{fontSize: 10}} className="bhi-sort-desc" data-test-id={"expand-metric"} />;

  let errorLabel;
  // NOTE we pretend to have a nameToCount map here to reuse the issue generating function
  const nameToCount = new Immutable.Map({[getNameForValidation(kpi)]: isNameDupe ? 2 : 1});
  const issues = getIssuesWithWrappedKpi(new Immutable.Map({testResult, kpi, combineError, submitError}), nameToCount);
  if (!issues.isEmpty()) {
    errorLabel = issues.join(", ");
  }

  const queryType = getQueryType(kpi, idToTemplate);
  const hasNewConfig = !!kpi.get("config");
  const statusCss = {display: "none", [`@media(min-width: 1280px)`]: {display: "inline"}};
  const nonEditableTooltipId = "non-editable-" + kpi.get("id");
  const notEditableEl = (
      <div style={{marginRight: 2}}>
        <div style={{zIndex: 9999}}>
          <ReactTooltip class="cube19-dark" id={nonEditableTooltipId} effect="solid" place="left">
            <div style={{fontSize: "0.8rem", lineHeight: "1.2rem"}}>This metric cannot be edited, duplicated or inherited from this page.<br/>
              If you need to make changes, please reach out to support.</div>
          </ReactTooltip>
        </div>
        <div style={{zIndex: 999}}>
          <i data-tip="" data-for={nonEditableTooltipId} className="bhi-non-editable" style={{opacity: 0.8, fontSize: "0.8rem", marginLeft: 10}} />
        </div>
      </div>
  );

  let statusEl;
  if (errorLabel) {
    statusEl = (
        <span
            style={{
              fontSize: "0.8rem",
              borderRadius: 5,
              padding: "5px 6px 5px 3px",
              marginLeft: 5,
              marginRight: 10,
              background: theme.palette.error.background,
              color: theme.palette.error.main
            }}>
          <i className="fa fa-exclamation" style={{marginLeft: "0.3rem"}} />
          <span css={statusCss}>{" " + errorLabel}</span>
        </span>);
  } else if (requiresExplanationUpdate) {
    statusEl = (
        <span
            style={{
              fontSize: "0.8rem",
              borderRadius: 5,
              padding: "5px 6px 5px 3px",
              marginLeft: 5,
              marginRight: 10,
              background: theme.palette.hints.background,
              color: theme.palette.hints.text
            }}>
          <i className="fa fa-exclamation" style={{marginLeft: "0.3rem"}} />
          <span css={statusCss}>&nbsp;Check explanation</span>
        </span>);
  } else if (hasChanged) {
    statusEl = (
        <span
            style={{
              fontSize: "0.8rem",
              borderRadius: 5,
              padding: "5px 6px 5px 3px",
              marginLeft: 5,
              marginRight: 10,
              background: theme.palette.success.background,
              color: theme.palette.success.main
            }}>
          <i className="fa fa-check" style={{marginLeft: "0.3rem"}} />
          <span css={statusCss}>&nbsp;Ready to Save</span>
        </span>);
  }
  const isLockedByOnboarding = kpi.get("createdByOnboarding")
      && currentClient.getImplementationStatus()
      === "ONBOARDING";
  const lockTooltipId = "lock-" + kpi.get("id");
  const masterMetricsAvailableToCombineWith = kpisWithMatchingRootEntity
      .filter(k => !isNaN(k.get("id")) && k.get("id") !== kpi.get("id"))
      .flatMap(kpi => kpiIdToMasterKpis.get(kpi.get("id"), Immutable.List()));
  const mirrorTooltipId = "mirror-" + kpi.get("id");
  const disabledTooltipId = "disabled-" + kpi.get("id");
  const kpisThatShareColumnsWithThisKpi = kpisWithMatchingColumns.filter(k => k.get("id") !== kpi.get("id") && !k.get("deleted"));

  const allDependentKpis = kpiIdToDependentKpis.get(kpi.get("id"), Immutable.List());
  const contributingKpis = kpiIdToContributingKpis.get(kpi.get("id"), Immutable.List());
  const dependencyIds = useCalculatedImmutableState(() => allDependentKpis.map(kpi => kpi.get("id")), [kpi, allDependentKpis]);
  const handleKpiChange = React.useCallback(kpi => onKpiChange(kpi, dependencyIds), [onKpiChange, dependencyIds]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const hasDependencies = !allDependentKpis.isEmpty();
  const isDependent = !contributingKpis.isEmpty();
  const hasRelationships = hasDependencies || isDependent;

  const kpiIsCombinationParent = !kpiIdToDependentKpis
      .get(kpi.get("id"), Immutable.List())
      .filter(kpi => kpi.get("dependencyType") === "COMBINATION")
      .isEmpty();

  const onDependencyIconClick = (e, tabId) => {
    e.stopPropagation();
    onNavigationClick(kpi.get("id"), tabId, true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const legacyConfigMessage = "This metric cannot be edited from this page. Reach out to support for any changes that are needed.";
  const inheritAndDuplicateDisabled = !isCube19User && !isKpiEditable(kpi, idToTemplate);
  return (
      <div
          id={kpi.get("id").toString()}
          style={{margin: "0.25rem"}}
          data-test-id={"edit-kpi-row"}>
        <div
            style={{
              backgroundColor: (kpi.get("visible")
                  ? theme.palette.background.card
                  : theme.palette.background.paper),
              opacity: kpi.get("visible") ? 1 : 0.6,
              borderRadius: 4,
              display: kpi.get("deleted") ? "none" : "flex",
              height: 40,
              lineHeight: "40px",
              paddingLeft: kpi.get("visible") ? 0 : 5,
              paddingBottom: kpi.get("visible") ? 0 : 2,
              cursor: "pointer",
            }}>
          <div
              onClick={() => onExpandClick(kpi, expanded)}
              style={{display: "flex", flexGrow: 1, marginLeft: -2, alignItems: "center"}}>
            <div style={{display: "flex"}}>
              <label style={{fontSize: 10, opacity: "40%", paddingRight: "5px"}}>Display:</label>
              <div data-test-id={"rename-kpi-display"}>
                <DelayedNameInput
                    placeholder="Type a Metric name"
                    value={kpi.get("name")}
                    onChange={val => handleKpiChange(kpi.set("name", val.substring(0, 64)))}
                    delayInMillis={1000}
                />
              </div>
            </div>

            {isCube19User &&
                <div style={{display: "flex"}}>
                  <label style={{fontSize: 10, opacity: "40%", padding: "0 5px 0 10px"}}>Original:</label>
                  <DelayedNameInput
                      placeholder="Type a Metric name"
                      value={kpi.get("trueName")}
                      onChange={val => handleKpiChange(kpi.set("trueName", val.substring(0, 64)))}
                      delayInMillis={1000} />
                </div>
            }
            {(errorLabel || requiresExplanationUpdate || hasChanged) && <div css={{flex: 1, textAlign: "right", paddingRight: 10}}>
              {statusEl}
            </div>
            }
          </div>
          {(!isKpiEditable(kpi, idToTemplate) && clientHasEdit && (!isCube19User || hasNewConfig)) && (
              <div css={{ display: "flex", textAlign: "right", paddingRight: 10 }}>
                {notEditableEl}
              </div>
          )}
          {!kpi.get("enabled") && <div style={{padding: "0 10px 0 0", display: "flex"}}>
            <div
                data-tip=""
                style={{
                  marginLeft: (hasRelationships || (isEditor && isLockedByOnboarding)) ? 10 : 0,
                  marginRight: 2,
                  marginTop: -1
                }}
                data-for={disabledTooltipId}>
              <div style={{zIndex: 9999}}>
                <ReactTooltip class="cube19-dark" id={disabledTooltipId} place="bottom" effect="solid">
                  <p style={{fontSize: 12, margin: 0}}>This metric has been disabled due to performance issues. If
                    you need help please submit a ticket.</p>
                </ReactTooltip>
              </div>
              <div style={{zIndex: 999}}>
                <i style={{color: theme.palette.error.main}} className="bhi bhi-caution-triangle-o" />
              </div>
            </div>
          </div>
          }
          {(!kpisThatShareColumnsWithThisKpi.isEmpty() || ((clientHasEdit || isEditor) && isLockedByOnboarding)) &&
              <div style={{padding: "0 10px 0 0", display: "flex"}}>
                {(clientHasEdit || isEditor) && isLockedByOnboarding &&
                    <div data-tip="" data-for={lockTooltipId} style={{marginRight: 2}}>
                      <div style={{zIndex: 9999}}>
                        <ReactTooltip class="cube19-dark" id={lockTooltipId} effect="solid" place="left">
                          <div>
                            <p>Metrics are currently locked by the Bullhorn Onboarding Team. Reach out to Bullhorn Support for more details.</p>
                          </div>
                        </ReactTooltip>
                      </div>
                      <div style={{zIndex: 999}}>
                        <FontAwesomeIcon icon={faLock} />
                      </div>
                    </div>}
                {!kpisThatShareColumnsWithThisKpi.isEmpty() &&
                    <div
                        data-tip=""
                        style={{
                          marginLeft: (hasRelationships || (isEditor && isLockedByOnboarding)) ? 10 : 0,
                          marginRight: 2

                        }} data-for={mirrorTooltipId}>
                      <div style={{zIndex: 9999}}>
                        <ReactTooltip class="cube19-dark" id={mirrorTooltipId} place="bottom" effect="solid">
                          {getMirrorTooltipContent(kpi, kpisThatShareColumnsWithThisKpi)}
                        </ReactTooltip>
                      </div>
                      <div style={{zIndex: 999}}>
                        <FontAwesomeIcon
                            icon={faBringForward}
                            style={{color: mirrorColor}}
                            onClick={event => onDependencyIconClick(event, "COLUMNS")} />
                      </div>
                    </div>}
              </div>}
          <div style={{display: "flex", alignItems: "center"}}>
            <Button
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                style={{
                  background: open ? theme.palette.background.paper : "transparent",
                  padding: 0,
                  height: 20,
                  lineHeight: "20px",
                  minWidth: 20,
                  flexShrink: "none",
                  boxShadow: "none",
                  borderRadius: 5,
                  marginRight: 10
                }}
                data-test-id="dropdown-options"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}>
              <i className="bhi-kebab-menu" style={{fontSize: 14}} />
            </Button>
            <Popper
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                placement="bottom-end" style={{zIndex: 100}}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      style={{background: theme.palette.background.card}}>
                    {((isEditor && isCube19User) || clientHasEdit) && kpi.get("visible") &&
                        <div title={inheritAndDuplicateDisabled ? legacyConfigMessage : "Duplicate"}>
                          <MenuItem
                              disabled={inheritAndDuplicateDisabled}
                              onClick={e => {
                                e.stopPropagation();
                                handleDuplicateClick(kpi.get("id"));
                                handleClose();
                              }}>
                            <div style={{fontSize: 12}}>
                              <i
                                  className="bhi-duplicate"
                                  style={{
                                    color: theme.themeId === "light" ? "#333333" : "white",
                                    marginRight: 10
                                  }} />
                              Duplicate Metric
                            </div>
                          </MenuItem>
                        </div>}
                    {((isEditor && isCube19User) || clientHasEdit) && kpi.get("visible") &&
                        <div title={inheritAndDuplicateDisabled ? legacyConfigMessage : "Inherit"}>
                          <MenuItem
                              disabled={inheritAndDuplicateDisabled}
                              onClick={e => {
                                e.stopPropagation();
                                handleInheritClick(kpi.get("id"));
                                handleClose();
                              }}>
                            <div style={{fontSize: 12}}>
                              <i
                                  className="far fa-copy"
                                  style={{
                                    color: theme.themeId === "light" ? "#333333" : "white",
                                    marginRight: 12
                                  }} />
                              Inherit from this Metric
                            </div>
                          </MenuItem>
                        </div>}
                    {isEditor && isCube19User && hasNewConfig &&
                        <MenuItem
                            onClick={e => {
                              e.stopPropagation();
                              onRevertToLegacyFormat(kpi.get("id"));
                              handleClose();
                            }}>
                          <div style={{fontSize: 12}}>
                            <i
                                className="fa fa-undo"
                                title="Revert"
                                style={{
                                  color: theme.themeId === "light" ? "#333333" : "white",
                                  fontSize: 10,
                                  marginRight: 12
                                }} />
                            Revert to legacy format
                          </div>
                        </MenuItem>}
                    <MenuItem
                        onClick={e => {
                          e.stopPropagation();
                          handleKpiChange(kpi.set("visible", !kpi.get("visible")));
                          handleClose();
                          if (kpi.get("visible")) {
                            Auditor.audit(
                                "edit-metrics-admin:hide-metric",
                                {kpiId: kpi.get("id")});
                          } else {
                            Auditor.audit(
                                "edit-metrics-admin:show-metric",
                                {kpiId: kpi.get("id")});
                          }
                        }}>
                      <div style={{cursor: "pointer", fontSize: 12}}>
                        {kpi.get("visible")
                            ? <><i
                                className="fa fa-eye-slash"
                                title="Hide this Metric in-app"
                                style={{marginLeft: -2, marginRight: 5, color: theme.palette.text.main}} /> Hide
                              Metric</>
                            : <><i
                                className="fa fa-eye"
                                title="Show this Metric in-app"
                                style={{
                                  marginLeft: -2,
                                  marginRight: 5,
                                  color: theme.palette.text.main
                                }} /> Show Metric</>}
                      </div>
                    </MenuItem>
                    {isEditor && isCube19User && <MenuItem
                        onClick={e => {
                          e.stopPropagation();
                          onDisableMetricToggle(kpi.get("id"), handleKpiChange);
                          handleClose();
                        }}>
                      <div
                          style={{cursor: "pointer", fontSize: 12}}>
                        {!kpi.get("enabled")
                            ? <><i
                                className="fa fa-check"
                                title="Enable this Metric in-app"
                                style={{marginLeft: -2, marginRight: 8, color: theme.palette.text.main}} /> Enable
                              Metric</>
                            : <><i
                                className="fa fa-times"
                                title="Disable this Metric in-app"
                                style={{marginLeft: 2, marginRight: 8, color: theme.palette.text.main}} /> Disable
                              Metric</>}
                      </div>
                    </MenuItem>}
                    {isEditor && isCube19User && <MenuItem
                        data-test-id="delete-metric-option"
                        onClick={e => {
                          e.stopPropagation();
                          onDeleteMetric(kpi.get("id"));
                          handleClose();
                        }}>
                      <div
                          style={{cursor: "pointer", fontSize: 12}}>
                        <><i
                                className="fa fa-trash"
                                title="Delete this Metric"
                                style={{marginLeft: 2, marginRight: 8, color: theme.palette.text.main}} /> Delete
                              Metric
                        </>
                      </div>
                    </MenuItem>}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </div>
          <div
              onClick={() => onExpandClick(kpi, expanded)}
              style={{display: "flex", justifyContent: "flex-end", cursor: "pointer", paddingRight: 5}}
              title="Expand to Edit Metric">
            {expandIcon}
          </div>
        </div>
        {expanded && <TabbedKpiEditor
            theme={theme}
            isEditor={isEditor}
            isCube19User={isCube19User}
            clientHasEdit={clientHasEdit}
            isLockedByOnboarding={isLockedByOnboarding}
            actionTypes={actionTypes}
            idToTemplate={idToTemplate}
            typeToGroupingEntity={typeToGroupingEntity}
            availableOwners={availableOwners}
            idToEntityColumn={idToEntityColumn}
            entityNames={entityNames}
            kpi={kpi}
            parentCombinedKpi={parentCombinedKpi}
            combinedKpi={combinedKpi}
            combineError={combineError}
            onKpiChange={handleKpiChange}
            kpiIsParent={kpiIsCombinationParent}
            columns={columns}
            submitError={submitError}
            onColumnsChange={handleColumnsChange}
            onUnmirror={onUnmirror}
            kpisWithMatchingRootEntity={kpisWithMatchingRootEntity}
            masterMetricsAvailableToCombineWith={masterMetricsAvailableToCombineWith}
            kpisThatShareColumnsWithThisKpi={kpisThatShareColumnsWithThisKpi}
            hasNewConfig={hasNewConfig}
            testResult={testResult}
            onTest={handleTest}
            testConfig={testConfig}
            onTestConfigChange={handleTestConfigChange}
            rootChanged={rootChanged}
            onDependencyClick={onNavigationClick}
            kpiIdToMasterKpis={kpiIdToMasterKpis}
            queryType={queryType}
            hasRelationships={hasRelationships}
            kpiIdToDependentKpis={kpiIdToDependentKpis}
            kpiIdToContributingKpis={kpiIdToContributingKpis}
            requiresExplanationUpdate={requiresExplanationUpdate}
            tabId={tabId}
            searchFocused={searchFocused}
            filterText={filterText}
            onChangeTab={onChangeTab}
            onClearRequiresExplanationUpdate={onClearRequiresExplanationUpdate} />}
      </div>);
});

const getTabIds = (isEditor, isCube19User, clientHasEdit, isLockedByOnboarding, queryType, hasNewConfig, hasRelationships, hasExplanationsEnabled, isAdminUser) => {
  let tabIds = Immutable.List();
  // all admin users get columns
  tabIds = tabIds.push("COLUMNS");

  if (!isLockedByOnboarding && ((isEditor && isCube19User) || clientHasEdit)) {
    if (hasNewConfig) {
      tabIds = tabIds.push("CONFIG");
    } else {
      if (isEditor && isCube19User) {
        // legacy tabs
        tabIds = tabIds.push("GENERAL");
        if (queryType) {
          tabIds = tabIds.push("DATE");
          if (queryType !== "SIMPLE_SUM" && queryType !== "FORWARD_REPORT") {
            tabIds = tabIds.push("FAST_FILTER");
          }
          tabIds = tabIds.push("OTHER");
          if (queryType !== "SIMPLE_SUM" && queryType !== "FORWARD_REPORT") {
            tabIds = tabIds.push("OWNERSHIP");
          }
        }
      } else {
        // external users always get the config tab if they are an editor
        tabIds = tabIds.push("CONFIG");
      }
    }

    tabIds = tabIds.push("TEST");
    if (isEditor && isCube19User) {
      if (queryType === "FORWARD_REPORT") {
        tabIds = tabIds.push("ACTIONABLE_INSIGHT");
      }
      tabIds = tabIds.push("IMPORT_EXPORT");
    }
  }

  if (hasRelationships) {
    tabIds = tabIds.push("RELATIONSHIPS");
  }
  if (hasExplanationsEnabled || isAdminUser) {
    tabIds = tabIds.push("EXPLANATION");
  }
  return tabIds;
};

const getAllRootNodes = (kpi, kpiIdToContributingKpis, kpiIdToDependentKpis) => {
  const startingNode = Immutable.Map({
    id: kpi.get("id"),
    name: `${kpi.get("name")}${kpi.get("deleted") ? " (deleted)" : ""}`
  });

  let [leafNodes, cyclingKpis] = getTerminalNodes(startingNode, kpiIdToDependentKpis, "down");
  let allRootNodes = Immutable.Set();

  if (!cyclingKpis) {
    leafNodes.every(leaf => {
      let [rootNodes, cyclingKpisFromLeaf] = getTerminalNodes(leaf, kpiIdToContributingKpis, "up");
      allRootNodes = allRootNodes.concat(rootNodes);
      cyclingKpis = cyclingKpisFromLeaf;
      return !cyclingKpisFromLeaf;
    });
  }

  return [allRootNodes, cyclingKpis];
};

const getTerminalNodes = (startingNode, kpiIdToNextGeneration, direction) => {
  let currentKpi = startingNode;
  let paths = Immutable.Set();
  let terminalNodes = Immutable.Set();
  let visitedNodes = Immutable.List();
  let cyclingKpis = null;

  while (currentKpi) {
    if (visitedNodes.includes(currentKpi)) {
      if (direction === "down") {
        terminalNodes = terminalNodes.add(currentKpi);
      } else {
        cyclingKpis = visitedNodes
            .push(currentKpi)
            .slice(visitedNodes.indexOf(currentKpi));
      }
      break;
    } else {
      visitedNodes = visitedNodes.push(currentKpi);
      const nextGenKpis = kpiIdToNextGeneration.get(currentKpi.get("id"));
      if (nextGenKpis) {
        if (nextGenKpis.size > 1) {
          paths = paths.union(nextGenKpis.rest().map(kpi => Immutable.Map({kpi, visitedNodes})));
        }
        currentKpi = nextGenKpis.first(Immutable.Map());
      } else {
        terminalNodes = terminalNodes.add(currentKpi);
        if (!paths.isEmpty()) {
          currentKpi = paths.first().get("kpi");
          visitedNodes = paths.first().get("visitedNodes");
          paths = paths.rest();
        } else {
          currentKpi = null;
        }
      }
    }
  }
  return [terminalNodes, cyclingKpis];
};

const DelayedNameInput = betterMemo(
    {displayName: "DelayedNameInput"},
    ({delayInMillis = 100, value, onChange, disabled, placeholder}) => {
      const [str, setStr] = React.useState(value);
      React.useEffect(() => setStr(value), [value]);

      const timeoutIdRef = React.useRef(null);

      const flushChanges = () => {
        clearTimeout(timeoutIdRef.current);
        onChange(str.trim());
      };

      const handleChange = newStr => {
        setStr(newStr.substring(0, 64));

        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => {
          onChange(newStr);
        }, delayInMillis);
      };

      return <SimpleTextInput
          type="text"
          placeholder={placeholder}
          value={str}
          onClick={e => e.stopPropagation()}
          disabled={disabled}
          customStyle={{marginTop: 2, width: "500px"}}
          onBlur={flushChanges}
          onChange={handleChange} />;
    });

export default EditKpiRow;
